<template>
  <div>
    <div class="login-form-wrap">
      <div class="marquee" style="position: absolute; width: 100%; height: 500px;z-index: 0;">
        <el-carousel height="500px">
          <el-carousel-item v-for="(item) in marquee" :key="item">
            <img alt class="background" style="width: 100%;height: 100%;object-fit: cover;"
                 :src="item"/>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="sctp-container flex flex-sb" style="z-index: 99;">
        <div>
          <template v-if="showLoginHints">
            <div class="flex flex-center" style="border: 5px solid #eee;padding: 15px;">
              <div style="width: 400px;height: 400px;">
                <img alt class="background" style="width: 100%;height: 100%;object-fit: cover;"
                     onerror="this.parentElement.removeChild(this)" :src="loginPageAdvs"/>
              </div>
              <div class="pad15"
                   style="width: 300px;height: 400px;color: white;background-color: rgba(0,0,0,.5);">
                <h1 class="fz-26 sctp-tc" style="margin-bottom: 20px;">温馨提示</h1>
                <div class="fz-16 mg-b15">您还未登录系统，无法完成操作，请登录后再试。</div>
                <div class="fz-16"><i class="el-icon-time mg-r5"></i>{{ dateToString(currentTime) }}
                </div>
              </div>
            </div>
          </template>
        </div>
        <div class="login-form sctp-bg-white" style="border: 1px solid #ccc">
          <el-tabs :stretch="true" v-model="loginType">
            <el-tab-pane label="账号密码登录" name="first">
              <el-form :model="accountForm" ref="accountForm"
                       autocomplete="off" :rules="accountFormRules">
                <el-form-item prop="username">
                  <el-input placeholder="账号/手机号/邮箱" v-model="accountForm.username"></el-input>
                </el-form-item>
                <el-form-item prop="password">
                  <el-input type="password" placeholder="密码长度为6~20位"
                            v-model="accountForm.password"></el-input>
                </el-form-item>
                <el-form-item>
                  <simp-drag-captcha @unloacked="unloackedPassLogin"></simp-drag-captcha>
                </el-form-item>
                <div class="sctp-pad-b10 space-between" style="font-size: 12px;">
                  <el-checkbox v-model="accountForm.rememberMe">两周内自动登录</el-checkbox>
                  <div>
                    <router-link :to="'/account-appeal/submit'">
                      <el-link type="info" style="color: orange;margin-right: 5px;">账号申诉</el-link>
                    </router-link>
                    <el-link type="info">
                      <router-link :to="'/forget-pwd'">忘记密码</router-link>
                    </el-link>
                  </div>
                </div>
                <el-button type="primary" @click="loginByAccount('accountForm')">登录</el-button>
              </el-form>
            </el-tab-pane>
            <el-tab-pane label="手机验证码登录" name="second">
              <el-form autocomplete="off" :model="smsForm" ref="smsForm" :rules="smsFormRules">
                <el-form-item prop="phone">
                  <el-input placeholder="输入手机号" v-model="smsForm.phone"></el-input>
                </el-form-item>
                <el-form-item prop="code">
                  <el-input placeholder="请输验证码" v-model="smsForm.code">
                    <template slot="append">
                      <el-button type="button"
                                 @click="sendSmsCaptchaCode()"
                      >
                        {{ sendSmsCaptchaCodeText }}
                      </el-button>
                    </template>
                  </el-input>
                </el-form-item>
                <div class="sctp-pad-b10 flex flex-sb">
                  <div>
                    <el-checkbox v-model="smsForm.autoRegister">自动注册</el-checkbox>
                  </div>
                  <router-link :to="'/account-appeal/submit'">
                    <el-link type="info" style="color: orange;">账号申诉</el-link>
                  </router-link>
                </div>
                <el-button type="primary" @click="loginByPhone('smsForm')">登录</el-button>
              </el-form>
            </el-tab-pane>
          </el-tabs>
          <div class="captcha"></div>
          <div style="font-size: 14px;">
            <div class="qr-code-login-tab">
              <span>扫码登录更方便</span>
              <img src="/images/login/qrcode.png"/>
            </div>
            <img src=""/>
          </div>
          <div class="space-between login-bottom">
            <div>
              <div class="mg-b5">
                <span>使用第三方账号登录</span>
              </div>
              <div>
                <img height="24px" src="/images/login/wechat.png"/>
                <img height="24px" src="/images/login/QQ.png"/>
                <img height="24px" src="/images/login/sinaBlog.png"/>
              </div>
            </div>
            <router-link style="color: orange;" :to="'/register'">注册新账号&gt;</router-link>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="账号注册成功"
      width="500px"
      :close-on-click-modal="false"
      :visible.sync="autoRegisterConfigDialogConfig.show"
      class="auto-register-dialog"
      @close="toIndexPage"
      center>
      <div>
        <el-form :model="autoRegisterConfigDialogConfig.form" ref="autoRegisterForm"
                 autocomplete="off"
                 :rules="autoRegisterConfigDialogConfig.formRules">
          <div class="mg-b15">请确认是否需要修改以下信息：</div>
          <el-form-item label="登录账号" prop="account">
            <el-input placeholder="4—20位字母、数字或下划线组合，不能为纯数字"
                      autocomplete="off"
                      v-model="autoRegisterConfigDialogConfig.form.account">
              <div slot="append">(确认后不可修改)</div>
            </el-input>
          </el-form-item>
          <el-form-item label="登录密码" prop="password">
            <el-input placeholder="6—20位字母、数字、特殊字符组合" type="password"
                      autocomplete="off"
                      v-model="autoRegisterConfigDialogConfig.form.password">
              <div slot="append">(登录后可修改)</div>
            </el-input>
          </el-form-item>
          <el-form-item label="账号类型" prop="userType">
            <div>
              个人
            </div>
          </el-form-item>
          <el-form-item>
            <el-button @click="autoRegisterConfigDialogConfig.onSubmitClick" type="primary"
                       size="small">保存
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import validator from '../../../resources/js/async-validator'
import {captcha, common, user} from '@/apis/index'
import $ from 'jquery'

const config = require('../../../resources/js/config')
require('@ASSET/libs/jigsaw/jigsaw.min')
export default {
  name: 'Login',
  components: {
    simpDragCaptcha: () => import('../../../components/common/SimpDragCaptcha')
  },
  data() {
    return {
      showLoginHints: false,
      loginPageAdvs: null,
      currentTime: new Date().getTime(),
      loginLock: true,
      loginType: 'first',
      accountForm: {
        username: '',
        password: '',
        rememberMe: false,
      },
      smsForm: {
        phone: '',
        code: '',
        autoRegister: true,
      },
      accountFormRules: {
        username: [
          {
            required: true,
            message: '账号或者手机号或者邮箱不能为空',
            trigger: 'blur'
          }
        ],
        password: [
          {
            required: true,
            message: '密码不能为空',
            trigger: 'blur'
          },
          {
            min: 6,
            max: 20,
            message: '密码长度在6~20位之间',
            trigger: 'blur'
          }
        ]
      },
      smsFormRules: {
        phone: [
          {
            required: true,
            message: '手机号不能为空',
            trigger: 'blur'
          },
          {
            validator: validator.validPhone,
            message: '手机号格式不正确',
            trigger: 'blur'
          }
        ],
        code: [
          {
            required: true,
            message: '验证码不能为空',
            trigger: 'blur'
          },
          {
            min: 6,
            max: 6,
            message: '密码长度为6位',
            trigger: 'blur'
          }
        ]
      },
      showCaptcha: false,
      sendSmsCaptchaCodeCountDown: -1,
      marquee: [],
      autoRegisterConfigDialogConfig: {
        show: false,
        form: {
          account: '',
          password: '',
        },
        formRules: {
          account: [
            {required: true, message: '账户不能为空', trigger: ['blur', 'change']},
            {min: 4, max: 20, message: '4—20位字母、数字或下划线组合，不能为纯数字', trigger: ['blur', 'change']},
            {
              required: true,
              trigger: ['blur', 'change'],
              validator: (rule, value, callback) => {
                //\u2E80-\u9FFF
                let reg = /^(?!\d+$)[\dA-Za-z_]{4,20}$/;
                reg.test(value)
                  ? callback()
                  : callback(new Error('4—20位字母、数字或下划线组合，不能为纯数字'))
              }
            },
            {
              validator: (rule, value, callback) => {
                if (!value) {
                  return;
                }
                user.checkAccountUniqueness({
                  account: value,
                  userId: (this.user && this.user.userId) || null
                }).then(res => {
                  const {retdata} = res;
                  if (retdata) {
                    callback(new Error('账号已存在'))
                  } else {
                    callback()
                  }
                }).catch(res => {
                  if (res) {
                    callback(new Error('校验异常'))
                  }
                })
              }, trigger: ['blur', 'change'],
            }
          ],
          password: [
            {required: true, message: '密码不能为空', trigger: ['blur', 'change']},
            {min: 6, max: 20, message: '密码长度在6~20位之间', trigger: ['blur', 'change']}
          ],
        },
        onSubmitClick: () => {
          this.$refs['autoRegisterForm'].validate()
          .then(res => {
            let {account, password} = this.autoRegisterConfigDialogConfig.form;
            user.saveAuthRegisterUserInfo({
              userId: this.user.userId,
              account: account,
              password: password
            }).then(res => {
              let {retdata: user, token} = res;
              this.$store.commit('account/setUser', user);
              localStorage.setItem("token", token);
              this.$message.success('保存成功!');
              this.toIndexPage();
            })
          })
        }
      }
    }
  },
  created() {
    this.smallServiceDialog();
  },
  watch: {
    '$route'(to, from) {
      if (to.path === from.path) {
        this.showLoginHints = true;
      }
    }
  },
  computed: {
    sendSmsCaptchaCodeText() {
      if (this.sendSmsCaptchaCodeCountDown > 0) {
        return `${this.sendSmsCaptchaCodeCountDown}秒后可重试`
      } else if (this.sendSmsCaptchaCodeCountDown === 0) {
        return '重试'
      } else {
        return '获取验证码'
      }
    }
  },
  mounted() {
    this.getTopMarquee()
    document.body.addEventListener('click', function (e) {
      if ($(e.target).parents('.captcha').length === 0 &&
        $(e.target).parents('button').length === 0 &&
        e.target.tagName !== 'BUTTON') {
        $('.captcha').html('')
      }
    }, false);
    let _this = this;
    this.timer = setInterval(() => {
      _this.currentTime = new Date();
    }, 1000)
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    toIndexPage() {
      this.$router.push({
        path: '/'
      });
    },
    unloackedPassLogin() {
      this.loginLock = false
    },
    async loginByAccount(formName) {
      // if (!await this.initCaptcha()) {
      //     return;
      // }
      if (this.loginLock) {
        this.$message.warning('请先滑动解锁')
        return
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          user.loginByUsernamePassword({
            username: this.accountForm.username,
            password: this.accountForm.password,
            rememberMe: this.accountForm.rememberMe,
          }).then(res => {
           const retdata = res.retdata;
            this.$store.commit('account/setUser', retdata.userInfoVO);
            localStorage.setItem("token", retdata.token);
            this.$Bus.$emit('login');
            this.toIndexPage();
          });
          return true
        }
        return false
      })
    },
    async loginByPhone(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          user.loginByCaptchaCode({
            phone: this.smsForm.phone,
            code: this.smsForm.code,
            autoRegister: this.smsForm.autoRegister,
          }).then(res => {
            let {retdata: user, token, registered} = res;
            this.$store.commit('account/setUser', user)
            localStorage.setItem("token", token);
            this.$Bus.$emit('login');
            if (registered) {
              this.autoRegisterConfigDialogConfig.show = true;
              this.autoRegisterConfigDialogConfig.form.account = user.account;
              this.autoRegisterConfigDialogConfig.form.password = '888888';
            } else {
              this.toIndexPage();
            }
          })
          return true
        }
        return false
      })
    },
    initCaptcha() {
      return new Promise(resolve => {
        window.jigsaw.init({
          el: $('.captcha')[0],
          onSuccess: () => {
            $('.captcha').html('')
            resolve(true)
          },
          onFail: () => {
            resolve(false)
          },
          onRefresh: () => {
          }
        })
      })
    },
    startSendSmsCaptchaCodeCountDown() {
      if (this.sendSmsCaptchaCodeCountDown <= 0) {
        return
      }
      this.sendSmsCaptchaCodeCountDown--
      setTimeout(this.startSendSmsCaptchaCodeCountDown, 1000)
    },
    stopSendSmsCaptchaCodeCountDown() {
      this.sendSmsCaptchaCodeCountDown = 0
    },
    async sendSmsCaptchaCode() {
      if (!await this.initCaptcha()) {
        return
      }
      if (this.sendSmsCaptchaCodeCountDown > 0) {
        return
      }
      this.sendSmsCaptchaCodeCountDown = 60
      this.startSendSmsCaptchaCodeCountDown()
      captcha.sendPhoneCode({
        phone: this.smsForm.phone
      }).then(res => {
        this.$message.success('验证码发送成功，请注意查收!')
      }).catch(res => {
        this.stopSendSmsCaptchaCodeCountDown()
      })
    },
    getTopMarquee() {
      common.loadImage({
        type: 'loginBanner'
      }).then(res => {
        this.marquee = res.retdata.map(item => item.imgurl)
      });
      common.loadImage({
        type: 'LoginPageAdvImgUrl'
      }).then(res => {
        let {retdata} = res;
        if (retdata && retdata.length > 0) {
          this.loginPageAdvs = retdata.map(item => item.imgurl)[0]
        }
      })
    },
    smallServiceDialog() {
      let maxDom = document.getElementById('smallView');
      if (maxDom) {
        maxDom.click();
      }
    }
  }
}
</script>

<style scoped>
.login-form-wrap {
  min-height: 500px;
  display: flex;
  position: relative;
}

.login-form-wrap .login-form {
  width: 325px;
  border-radius: 3px;
  background: #fff;
  padding: 20px 30px;
  position: relative;
}

.login-form .el-tabs__content {
  min-height: 300px;
}

.login-form .el-form {
  padding-top: 7px;
}

.login-form .el-form .el-button {
  display: block;
  width: 100%;
}

.qr-code-login-tab {
  font-size: 12px;
  position: absolute;
  top: 5px;
  right: 5px;
}

.qr-code-login-tab > span {
  position: relative;
  top: -43px;
  padding: 2px 5px;
  border: 1px solid #f90;
  border-radius: 3px;
  color: orange;
}

.qr-code-login-tab > span:after {
  content: '';
  position: absolute;
  border: 1px solid orange;
  width: 6px;
  height: 6px;
  right: -4px;
  bottom: 7px;
  transform: rotate(45deg);
  background-color: #FFF;
  border-left-color: #FFF;
  border-bottom-color: #FFF;
}

.captcha {
  position: absolute !important;
  bottom: 58px;
  background-color: #FFF;
  box-shadow: 0 0 10px 1px #ccc;
  z-index: 1;
}

.space-between {
  display: flex;
  justify-content: space-between;
  padding: 5px;
}

.login-bottom {
  font-size: 12px;
  background-color: #e8e8ee;
  width: calc(100% + 60px);
  position: relative;
  margin-top: -20px;
  left: -30px;
  right: -30px;
  bottom: -20px;
  padding: 12px 30px;
}
</style>
